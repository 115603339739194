<template>
  <div>
    <div class="flex mb-4 justify-between">
      <h1 class="font-medium text-3xl">{{ $t("trains") }}</h1>
      <button
        v-if="role == 100"
        @click="open = true"
        class="bg-green-500 px-3 rounded text-gray-200"
      >
        <i class="el-icon-plus"></i> {{ $t("add") }}
      </button>
    </div>
    <el-table
      :data="trainData"
      border
      v-loading="loading"
      style="width: 100%"
      class="rounded-lg shadow-md"
    >
      <el-table-column align="center" prop="id" label="ID" width="60">
      </el-table-column>
      <el-table-column prop="name" :label="$t('name')" min-width="300">
      </el-table-column>
      <el-table-column prop="mileage" :label="$t('mileage')" min-width="90">
      </el-table-column>
      <el-table-column v-if="trainData.length > 0" align="center">
        <template slot="header">
          <div class="mb-2">
            {{ $t('next_corrective_type') }}
          </div>
          <div class="flex justify-center items-center gap-2">
            <el-tag
              type="danger"
              size="small"
            >
              {{ $t('maximal') }}
            </el-tag>
            <el-tag
              type="success"
              size="small"
            >
              {{ $t('average') }}
            </el-tag>
            <el-tag
              type="warning"
              size="small"
            >
              {{ $t('minimal') }}
            </el-tag>
        </div>
        </template>
        <el-table-column
          prop="mileages"
          align="center"
          v-for="(mileage, key) in trainData[0].mileages"
          :key="mileage.id"
          :label="key"
          min-width="100"
        >
          <template slot-scope="scope">
            <div class="flex flex-col items-center gap-1">
              <el-tag
                size="mini"
                type="danger"
                >
                {{ scope.row.mileages[key].mileage_diff_maximal }}
              </el-tag>
              <el-tag
                size="mini"
                type="success"
                >
                {{ scope.row.mileages[key].mileage_diff_average }}
              </el-tag>
              <el-tag
                size="mini"
                type="warning"
                >
                {{ scope.row.mileages[key].mileage_diff_minimal }}
              </el-tag>
            </div>
          </template>
        </el-table-column>
      </el-table-column>

      <el-table-column :label="$t('repair_count')" align="center">
        <el-table-column
          prop="total_repairs_count"
          :label="$t('total_repairs_count')"
          min-width="150"
        >
        </el-table-column>
        <el-table-column
          prop="not_completed_repairs_count"
          :label="$t('not_completed_repairs_count')"
          min-width="150"
        >
        </el-table-column>
        <el-table-column
          prop="need_spare_repairs_count"
          :label="$t('need_spare_repairs_count')"
          min-width="150"
        >
        </el-table-column>
        <el-table-column
          prop="postponed_repairs_count"
          :label="$t('postponed_repairs_count')"
          min-width="150"
        >
        </el-table-column>
      </el-table-column>
      <el-table-column
        prop="created_time"
        :label="$t('created_time')"
        min-width="180"
      >
      </el-table-column>
      <el-table-column
        prop="last_updated_time"
        :label="$t('last_updated_time')"
        min-width="250"
      >
      </el-table-column>
      <el-table-column
        fixed="right"
        :label="$t('actions')"
        width="280"
        v-if="role == 100"
      >
        <template slot-scope="scope">
          <el-button
            icon="el-icon-tickets"
            size="medium"
            @click="openLog(scope.row.id)"
          >
          </el-button>
          <router-link :to="`/trains/${scope.row.id}`" class="mx-2">
            <el-button size="medium" icon="el-icon-view"> </el-button>
          </router-link>
          <el-button size="medium" @click="openEdit(scope.row.id)">
            <i class="el-icon-edit"></i>
          </el-button>
          <el-popconfirm
            :confirm-button-text="$t('yes')"
            :cancel-button-text="$t('no_thanks')"
            icon="el-icon-info"
            icon-color="red"
            :title="$t('you_want_to_delete')"
            class="ml-2"
            @confirm="trainDelete(scope.row.id)"
          >
            <el-button
              slot="reference"
              icon="el-icon-delete"
              size="medium"
              type="danger"
            >
            </el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
      <el-table-column
        fixed="right"
        :label="$t('actions')"
        min-width="100"
        v-else
      >
        <template slot-scope="scope">
          <router-link :to="`/trains/${scope.row.id}`" class="mr-2">
            <el-button size="medium" icon="el-icon-view"> </el-button>
          </router-link>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog :title="$t('logs')" class="log_dialog" :visible.sync="logDialog">
      <el-table :data="train_logs" border v-loading="log_loading">
        <el-table-column
          property="user"
          :label="$t('user')"
          min-width="150"
        ></el-table-column>
        <el-table-column
          property="text"
          :label="$t('text')"
          min-width="300"
        ></el-table-column>
        <el-table-column
          property="created_time"
          :label="$t('created_time')"
          width="180"
        ></el-table-column>
      </el-table>
    </el-dialog>
    <div class="mt-3 flex justify-end">
      <el-pagination
        background
        layout="prev, pager, next"
        hide-on-single-page
        @prev-click="Prev"
        @next-click="Next"
        @current-change="handleCurrentChange"
        :total="totalCount"
      >
      </el-pagination>
    </div>
    <el-drawer
      :visible.sync="open"
      :with-header="false"
      size="400px"
      @close="CloseDrawer"
      v-if="role == 100"
    >
      <div
        class="h-20 bg-111E52 flex items-center justify-between px-3 text-white text-xl"
      >
        <p>EXSERVICE</p>
        <button @click="open = false" class="px-2">
          <i class="el-icon-close"></i>
        </button>
      </div>
      <div class="p-4">
        <h1 class="mb-4">{{ $t("add_train") }}</h1>
        <el-form ref="train" :rules="rules" label-position="top" :model="train">
          <el-form-item
            prop="name"
            :rules="{
              required: true,
              message: $t('please_input'),
              trigger: 'blur',
            }"
            style="margin-bottom: 10px"
            :label="$t('name')"
          >
            <el-input :placeholder="$t('input')" v-model="train.name" clearable>
            </el-input>
          </el-form-item>
          <el-form-item
            prop="mileage"
            style="margin-bottom: 10px"
            :label="$t('mileage')"
          >
            <el-input
              :placeholder="$t('input')"
              v-model.number="train.mileage"
              clearable
            >
              >
            </el-input>
          </el-form-item>
        </el-form>

        <div class="text-right">
          <el-button
            v-if="!edit"
            @click="addTrain"
            type="primary"
            :loading="loading"
          >
            {{ $t("add") }}
          </el-button>
          <el-button
            v-else
            @click="editTrain"
            type="primary"
            :loading="loading"
          >
            {{ $t("save") }}
          </el-button>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "Trains",
  data() {
    var checkNumber = (rule, value, callback) => {
      if (value) {
        if (!Number.isInteger(value)) {
          callback(new Error(this.$t("please_input_digits")));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    return {
      logDialog: false,
      log_loading: false,
      train_logs: [],
      open: false,
      edit: false,
      id: "",
      edit_train: "",
      trainData: [],
      train: {
        name: "",
        mileage: "",
      },
      rules: {
        mileage: [
          {
            validator: checkNumber,
            trigger: "blur",
          },
        ],
      },
      totalCount: 0,
      count: 1,
      loading: false,
      role: localStorage.role,
    };
  },
  created() {
    this.getTrains();
  },
  methods: {
    openLog(id) {
      this.logDialog = true;
      this.log_loading = true;
      axios
        .get(`trains/${id}/logs/`)
        .then((response) => {
          this.log_loading = false;
          this.train_logs = response.data.map((data) => {
            return {
              ...data,
              created_time: data.created_time
                .split(".")
                .shift()
                .replace("T", " "),
            };
          });
        })
        .catch(() => {
          this.log_loading = false;
        });
    },
    async getTrains() {
      this.loading = true;
      await axios
        .get(`trains/${this.count === 1 ? "" : "?page=" + this.count}`)
        .then((response) => {
          this.totalCount = response.data.count;
          this.trainData = response.data.results.map((data) => {
            return {
              ...data,
              created_time: data.created_time
                .split(".")
                .shift()
                .replace("T", " "),
              last_updated_time: data.last_updated_time
                .split(".")
                .shift()
                .replace("T", " "),
            };
          });
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    Prev() {
      this.count--;
      this.getTrains();
    },
    handleCurrentChange(val) {
      this.count = val;
      this.getTrains();
    },
    Next() {
      this.count++;
      this.getTrains();
    },
    async trainDelete(id) {
      this.loading = true;
      await axios
        .delete(`trains/${id}/`)
        .then(() => {
          this.totalCount % 10 === 1 && this.totalCount / 10 >= 1
            ? this.count--
            : "";
          this.loading = false;
          this.getTrains();
          this.$notify({
            title: this.$t("success"),
            message: this.$t("data_deleted"),
            type: "success",
          });
        })
        .catch(() => {
          this.loading = false;
          this.$notify.error({
            title: this.$t("error"),
            message: this.$t("data_not_deleted"),
          });
        });
    },
    CloseDrawer() {
      this.edit = false;
      Object.keys(this.train).forEach((key) => {
        this.train[key] = "";
      });
      this.$refs.train.clearValidate();
    },
    addTrain() {
      this.$refs.train.validate((valid) => {
        if (valid) {
          let data = Object.assign({}, this.train);
          Object.keys(data).forEach((key) => {
            if (data[key] === "") {
              delete data[key];
            }
          });
          this.loading = true;
          axios
            .post("trains/", data)
            .then(() => {
              this.loading = false;
              this.open = false;
              this.getTrains();
              this.$notify({
                title: this.$t("success"),
                message: this.$t("data_added"),
                type: "success",
              });
            })
            .catch(() => {
              this.loading = false;
              this.$notify.error({
                title: this.$t("error"),
                message: this.$t("no_data_added"),
              });
            });
        }
      });
    },
    openEdit(id) {
      this.open = true;
      this.edit = true;
      this.id = id;
      this.edit_train = this.trainData.find((data) => data.id === id);
      Object.keys(this.train).forEach((key) => {
        this.train[key] = this.edit_train[key];
      });
    },
    editTrain() {
      this.$refs.train.validate((valid) => {
        if (valid) {
          let data = Object.assign({}, this.train);
          Object.keys(data).forEach((key) => {
            if (data[key] === "") {
              delete data[key];
            }
          });
          this.loading = true;
          axios
            .patch(`trains/${this.id}/`, data)
            .then(() => {
              this.loading = false;
              this.open = false;
              this.getTrains();
              this.$notify({
                title: this.$t("success"),
                message: this.$t("data_changed"),
                type: "success",
              });
            })
            .catch(() => {
              this.loading = false;
              this.$notify.error({
                title: this.$t("error"),
                message: this.$t("data_unchanged"),
              });
            });
        }
      });
    },

    // tableRowClassName({ row }) {
    //   if (row.color == 1) {
    //     return "success-row";
    //   } else if (row.color == 2) {
    //     return "warning-row";
    //   } else if (row.color == 3) {
    //     return "danger-row";
    //   }
    //   return "";
    // },
  },
};
</script>

<style lang="scss">
.el-table .warning-row {
  background: #facc15;
}

.el-table .success-row {
  background: #84cc16;
}
.el-table .danger-row {
  background: #f87171;
}
.el-table .cell {
  word-break: break-word;
}
.log_dialog {
  z-index: 99999 !important;
  .el-dialog {
    width: 900px;
    @media screen and (max-width: 1400px) {
      width: 700px;
    }
    @media screen and (max-width: 1200px) {
      width: 600px;
    }
  }
}
</style>
